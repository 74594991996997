::-webkit-scrollbar {
  width: 4px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
}

html {
  background: url('./images/main_background.jpg') no-repeat center center  fixed;
  background-size: cover;
}

body,
#root {
  background: transparent !important;
}

#side_menu {
  background: url('./images/side_menu_bg.jpg') no-repeat scroll center top transparent;
  background-size: cover;
  width: 260px;
  height: 100vh;
}
#side_menu,
#content,
.transactional_bloc {
  transition: 0.5s all linear;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: linear;
  transition-delay: 0s;
}

.nav-link.active,
.nav-pills .show > .nav-link {
  background-color: rgba(68, 197, 247, 0.59) !important;
}

.dygraph-annotation {
  position: absolute;
  display: block;
  color: grey !important;
  font-size: 12px !important;
  padding-left: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1 !important;
}
[id^='graph'] {
  width: 100% !important;
  background: #fff;
}

.card-header {
  padding: 0 !important;
}
.card-header button {
  display: inherit;
  text-align: left;
  width: 100%;
  padding: 18px 20px;
  margin: 0;
  font-size: 15px;
  text-decoration: none !important;
  font-size: 16px;
  transition: 0.2s all linear;
  font-weight: bold;
}

.card-header button:hover,
.card-header .arrowfun {
  transition: 0.2s all linear;
  background: #2e505a !important;
  color: #fff !important;
}
.arrowfun i {
  transform: rotateZ(180deg);
  transition: 0.2s all linear;
}

.nav-tabs .nav-link.active {
  background-color: #fff0 !important;
  border: 3px solid white !important;
}

.nav-link {
  color: #fff !important;
  text-transform: uppercase;
  text-align: left;
  font-weight: 600;
  font-size: 17px;
}

.dygraph-label-rotate-right .dygraph-ylabel {
  position: relative;
  bottom: 120px;
  left: 10px;
  transform: rotate(269deg);
}

.dygraph-title {
  float: right;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.dygraph-xlabel {
  text-align: center;
  position: relative;
  top: -6px;
}

.dygraph-axis-label-y,
.dygraph-axis-label-x {
  font-size: 12px;
  font-weight: 600;
}

.table td,
.table th {
  padding: 0.25rem !important;
}

.xyData {
  overflow-y: scroll;
  overflow-x: scroll;
  word-break: break-word;
  padding: 20px;
}

.xyDataContent {
  color: rgb(0, 0, 0);
  font-size: 12px !important;
  text-align: left !important;
}

.csvText {
  text-align: left;
  font-weight: 400;
  overflow: auto;
  font-family: monospace;
  user-select: text;
  white-space: pre;
}
@media only screen and (max-width: 600px) {
  .xyData {
    overflow-y: scroll;
    overflow-x: scroll;
    word-break: break-word;
    padding: 20px;
  }
}

.atomtable {
  position: relative;
}

.atomtable table {
  margin: auto;
}

.atomtable .pagination {
  margin: auto;
  display: block !important;
  margin-top: 15px;
}

.atomtable table {
  background: rgba(240, 248, 255, 0.8196078431372549) !important;
  width: 100%;
  border: 4px solid black !important;
}

select {
  border: 1px solid white;
  color: white;
  background: none;
}

.atomtable td p {
  margin-bottom: 2px;
  font-weight: 500 !important;
}

#number::-webkit-outer-spin-button,
#number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']:hover,
input[type='number']:focus {
  -moz-appearance: number-input;
}

@media only screen and (min-width: 700px) {
  .gear-image-mobile {
    display: none;
  }
}

.atomtable td {
  font-weight: 500 !important;
}

@media only screen and (max-width: 600px) {
  @font-face {
    font-family: 'Agency FB';
    src: url('./fonts/Agency_FB_V1.eot');
    src: url('./fonts/Agency_FB_V1.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Agency_FB_V1.woff') format('woff'),
      url('./fonts/Agency_FB_V1.ttf') format('truetype'),
      url('./fonts/Agency_FB_V1.svg#Agency-FB') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  .atom-heading {
    color: white !important;
    font-family: 'Agency FB' !important;
    font-size: 3.5rem !important;
    font-weight: 600 !important;
    letter-spacing: -2px !important;
    text-shadow: 3px 0px #000000 !important;
  }
}

@font-face {
  font-family: 'Agency FB';
  src: url('./fonts/Agency_FB_V1.eot');
  src: url('./fonts/Agency_FB_V1.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Agency_FB_V1.woff') format('woff'),
    url('./fonts/Agency_FB_V1.ttf') format('truetype'),
    url('./fonts/Agency_FB_V1.svg#Agency-FB') format('svg');
  font-weight: normal;
  font-style: normal;
}

.atom-heading {
  color: white;
  font-family: 'Agency FB';
  font-size: 3.5rem;
  font-weight: 600;
  letter-spacing: 2px;
  text-shadow: 6px 0px #000000;
}

.dygraph-axis-label.dygraph-axis-label-x {
  color: black;
}

.dygraph-axis-label.dygraph-axis-label-y {
  color: black;
}

.dygraph-label.dygraph-ylabel {
  color: black;
}

.dygraph-label.dygraph-xlabel {
  color: black;
}

.dygraph-legend {
  color: black;
}

.dygraph-label.dygraph-title {
  color: black;
}

.nav-tabs .nav-link.active {
  background-color: #fff0 !important;
  border: 3px solid white !important;
}

.tableclass th,
.tableclass td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.custom-control-input:checked ~ .custom-control-label::before {
  width: 50px;
  height: 25px;
  border-radius: 35px;
}

.fil-dat-se {
  float: left;
}

.custom-switch {
  padding-right: 15px;
  margin-bottom: 14px;
}

.custom-switch span {
  vertical-align: text-bottom;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  border-radius: 35px;
  width: 23px;
  height: 23px;
  transform: translateX(1.7rem) !important;
  margin-top: 0px;
  margin-left: -4px;
}

.custom-switch .custom-control-label::before {
  width: 50px !important;
  height: 25px;
  border-radius: 35px !important;
}

.custom-switch .custom-control-label::after {
  border-radius: 35px !important;
  width: 23px !important;
  height: 23px !important;
  margin-left: 0;
  margin-top: -1px !important;
}

.secondtable {
  height: 2px;
  overflow: auto;
  margin-left: -99999999px;
  width: 1px;
}

.alert-heading.h4 {
  color: inherit !important;
  border-radius: 3px !important;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  font-size: 20px;
  font-size: 14px;
  padding: 15px !important;
  width: 170px !important;
  margin: auto !important;
  text-align: center !important;
  color: #fff !important;
  background: rgba(43, 128, 255, 0.9) !important;
}

.alert-success {
  position: absolute !important;
  top: -10px !important;
  right: 40px !important;
  margin: auto !important;
  text-align: center !important;
  z-index: 111;
}

.alert {
  background-color: rgba(0, 0, 0, 0) !important;
  border-color: rgba(0, 0, 0, 0) !important;
}

.mftable table thead tr th:nth-of-type(1) .formf {
  display: none !important;
}
.grouptable table thead tr:nth-of-type(1) th:nth-of-type(7),
.grouptable table tbody tr td:nth-of-type(7) {
  display: none;
}

@media (max-width: 640px) {
  .pep table thead tr:nth-of-type(1) th:nth-of-type(2),
  .pep table thead tr:nth-of-type(2) th:nth-of-type(2) {
    display: none !important;
  }
  .pep table tbody tr td:nth-of-type(2) {
    display: none;
  }
  .mftable table thead tr:nth-of-type(2) {
    display: none;
  }
  .atomictable table thead tr:nth-of-type(1) {
    display: none;
  }
  .atomictable table thead tr:nth-of-type(2) th:nth-of-type(1),
  .atomictable table thead tr:nth-of-type(2) th:nth-of-type(6) {
    display: none;
  }
  .atomictable table tbody tr td:nth-of-type(1),
  .atomictable table tbody tr td:nth-of-type(6) {
    display: none;
  }

  .mftable_mobile table thead tr:nth-of-type(1) th:nth-of-type(5),
  .mftable_mobile table thead tr:nth-of-type(1) th:nth-of-type(6),
  .mftable_mobile table thead tr:nth-of-type(2) th:nth-of-type(5),
  .mftable_mobile table thead tr:nth-of-type(2) th:nth-of-type(6),
  .mftable_mobile table tbody tr td:nth-of-type(5),
  .mftable_mobile table tbody tr td:nth-of-type(6) {
    display: none;
  }
}
